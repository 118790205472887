
// @import "assets/css/main.css";
/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';
// @import '../projects/mdb-angular-ui-kit/assets/scss/mdb.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import "ngx-toastr/toastr-bs4-alert.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/6.0.1/mdb.min.css";
// @import "smartwizard/dist/css/smart_wizard_all.css";

// @import "~ngx-toastr/toastr-bs4-alert.scss";

@import "~intl-tel-input/build/css/intlTelInput.css";
// @import "@yaireo/tagify/dist/tagify.css";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root {
      --primary-color: #163A61;
    --secondary-color: #d90845;
         --main-color: #0f172a;
         --text-color: #4C505A;
         --blue-color: #2f80ed;
      --dark-bg-color: #212043;
}
* {
    outline: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*:focus {
    outline: none;
}
body {
    font-family: 'Poppins', sans-serif;
    color: var(--text-color);
    background-color: #fff;
    font-size: 1rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}
input[type="text"],
input[type="password"],
input[type="email"],
textarea,
select {
    outline: none;
}
input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}
a,
button {
    color: var(--primary-color);
    text-decoration: none;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
a:hover,
button:hover {
    color: var(--primary-color);
    text-decoration: none;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
a:focus {
    text-decoration: none;
}
h1,h2,h3,h4,h5,h6{
    color: var(--main-color);
}
p {
    font-weight: 400;
    -moz-text-shadow: none;
    -ms-text-shadow: none;
    text-shadow: none;
}
p,
li {
    font-size: 0.9rem;
    line-height: 1.5;
    font-weight: 300;
}

.abstract_wrap{
    margin: 0;
    width: 100%;
}
.abstract_grid{
    display: flex;
    min-height: 100vh;
}
.abstract_banner{
    margin: 0;
    width: 100%;
    max-width: 400px;
    background-color: var(--primary-color);
    color: #fff;
    overflow-y: auto;
    height: 100vh;
    position: sticky;
    top: 0;
}
.wrap_banner_content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 2rem;
    min-height: 660px;
    height: 100%;
    flex: 1;
}
.sidebar_head {
    margin-top: 2rem;
}
.sidebar_head h1 {
    font-weight: 700;
    position: relative;
    padding-bottom: 1em;
    line-height: 1.1;
    color: #fff;
    font-size: 2rem;
}
.sidebar_head h1::after {
    content: "";
    width: 80px;
    height: 3px;
    background-color: var(--secondary-color);
    display: block;
    margin-top: 1rem;
}
.sidebar_body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
}
.brand_icon {
    background-color: #163A61;
    border-radius: .5rem;
    position: relative;
    cursor: pointer;
    padding: .4rem !important;
    display: flex;
    justify-content: center;
}
.wrap_banner_content .brand_icon {
    margin-bottom: 1.5rem;
}
.brand_icon img {
    width: auto;
    max-width: 100%;
    min-height: auto;
    max-height: none;
    height: auto ;
    max-height: 115px;
}
.banner_title h4 {
    margin-bottom: 3rem;
    font-weight: 400;
    // font-size: 1.6rem;
    color: #fff;
}
.banner_icon_list {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-bottom: .8rem;
}
.list_icon {
    background-color: var(--secondary-color);
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    border: 3px solid rgba(0,0,0,.2);
}
.banner_icon_list h5 {
    flex: 1 1;
    margin: 0;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.4;
    color: #fff;
}
.sidebar_foot {
    margin-bottom: 2rem;
}
.powered_by {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}
.powered_by img {
    width: 180px !important;
    bottom: 6px !important;
    position: relative;
}
.powered_by span {
    font-size: .85rem;
}
.abstract_content {
    margin: 0;
    width: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
}
.wrap_container{
    margin: 0 auto;
    width: 100%;
    max-width: 900px;
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 1.2rem;
}
.wrap_fluid {
    flex: 1 1;
    overflow-y: auto;
    padding: 1.5rem 0 0;
    display: flex;
    flex-direction: column;
}
.app_footer {
    border-top: 1px solid #eee;
}
.footer_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
}
.footer_flex p {
    margin: 0;
    font-weight: 300;
}
.footer_flex ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    gap: 20px;
}
.footer_flex ul li a {
    font-size: .85rem;
    font-weight: 300;
    color: var(--text-color);
}
.wrap_content{
    flex: 1;
    margin-bottom: 2rem;
}
.auth_heading {
    text-align: center;
    margin-bottom: 2rem;
}
.auth_heading h1 {
    font-weight: 700;
    font-size: 3rem;
    margin: .2rem 0 0;
}
.auth_heading h3 {
    margin-bottom: 0;
    position: relative;
    font-size: 1.2rem;
}
.auth_heading h3::after {
    content: "";
    width: 80px;
    height: 3px;
    background-color: var(--secondary-color);
    display: block;
    margin: 1rem auto;
}
.auth_middle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}
.auth_form {
    display: flex;
    flex-direction: column;
    max-width: 430px;
    width: 100%;
    background-color: #fff;
    padding: 2rem 2rem 3rem;
    box-shadow: 0 8px 26px 0 rgba(0,0,0,.1);
    margin: 1rem auto;
}
.auth_form_head {
    margin-bottom: 1rem;
}
.auth_form_head h2 {
    margin-bottom: 4px;
    font-weight: 600;
    font-size: 1.5rem;
}
.auth_form_head p {
    margin: 0 0 0.5rem;
}
.app_btn {
    padding: 0.2rem 1.4rem;
    font-size: 0.9rem;
    color: var(--main-color);
    font-weight: 500;
    border-radius: 4px;
    background-color: #eee;
    cursor: pointer;
    min-height: 46px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.btn_primary{
    background-color: var(--primary-color);
    color: #fff;
    border: none;
}
.btn_primary:hover,
.btn_primary:focus,
.btn_primary:active{
    color: #fff !important;
    background-color: var(--primary-color) !important;
}
.btn_primary_outline{
    background-color: transparent;
    box-shadow: inset 0px 0px 0px 1px var(--primary-color);
    color: var(--primary-color);
    border: none;
}
.btn_primary_outline:hover,
.btn_primary_outline:active,
.btn_primary_outline:focus{
    color: var(--primary-color);
    box-shadow: inset 0px 0px 0px 1px var(--primary-color) !important;
}
.btn_secondary{
    background-color: var(--secondary-color);
    color: #fff;
    border: none;
}
.btn_secondary:hover,
.btn_secondary:focus,
.btn_secondary:active{
    color: #fff !important;
    background-color: var(--secondary-color) !important;
}
.auth_form_group{
    margin-bottom: 1rem;
}
.app_input{
    border: 1px solid #d5d6d9;
    min-height: 52px;
    padding: 0.5rem 1.1rem;
    font-weight: 400;
    font-size: 0.9rem;
    color: var(--main-color);
    border-radius: 4px;
    width: 100%;
    transition: all 0.2s ease-in-out;
}
.app_input:focus{
    border-color: rgb(25, 118, 210);
}
.auth_form_body .app_btn{
    width: 100%;
    text-transform: uppercase;
}
.auth_btn_block {
    margin-bottom: 1rem;
}
.auth_btn_block .stack_btn_group {
    grid-gap: 1rem;
    gap: 1rem;
    font-size: .9rem;
    padding-top: .5rem;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
}
.stack_btn_group .link_btn {
    border-bottom: 1px solid var(--primary-color);
    border-radius: 0;
    cursor: pointer;
    text-transform: none;
    color: var(--main-color);
    letter-spacing: normal;
    font-weight: 400;
}
.form-outline{
    margin-bottom: 1.2rem;
}
.form-default{
    margin-bottom: 1.5rem;
}
.form-outline .form-control{
    min-height: 52px;
    font-size: 0.9rem;
    color: var(--main-color);
    background-color: #fff;
}
.form-outline .form-control ~ .form-label {
    padding-top: 0.9rem;
    font-size: 0.9rem;
}
.form-outline .form-control.active ~ .form-label, .form-outline .form-control:focus ~ .form-label {
    transform: translateY(-1.4rem) translateY(.1rem) scale(.8);
}
.btn_close,
.btn_close:focus,
.btn_close:hover{
    background-color: rgba(0,0,0,.0);
    color: var(--text-color);
    box-shadow: none !important;
}
.btn_link,
.btn_link:focus,
.btn_link:hover{
    background-color: rgba(0,0,0,.03);
    color: var(--secondary-color);
    box-shadow: none !important;
}

.modal-title{
    font-size: 1.2rem;
}
.btn-close{
    background-size: 12px;
}
.static a{
    color: var(--secondary-color);
}
.static strong{
    font-weight: 600;
}
.static ul{
    padding-left: 15px;
}
.static ol{
    padding-left: 25px;
}
.static li{
    margin-bottom: 0.5rem;
}
.static h6{
    font-size: 1.05rem;
    margin-bottom: 1.2rem;
}
.modal-header,
.modal-footer,
.modal-body{
    padding: 1.2rem 1.4rem;
}
.modal-body{
    padding-top: 0.4rem;
}
.modal-header{
    border-bottom: none;
}
.modal-footer{
    border-top: none;
    padding-top: 0.4rem;
}
.static_block {
    margin-bottom: 2.4rem;
}
.btn-outline-dark{
    background-color: transparent;
}
.or{
    display: flex;
    justify-content: center;
    text-align: center;
    font-style: italic;
    opacity: .6;
    padding: 1rem 0;
    font-size: .8rem;
}
.opt_grid{
    display: grid;
    gap: 0.5rem;
}
.opt_grid.box_3{
    grid-template-columns: 1fr 1fr 1fr;
}
.opt_grid.box_4{
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.opt_grid.box_5{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.opt_grid.box_6{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.opt_grid input{
    text-align: center;
}
.app_header {
    padding: 0 2rem;
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.1);
    position: sticky;
    top: 0;
    z-index: 999;
}
.header_flex, .header_left, .header_right {
    display: flex;
    align-items: center;
}
.app_header .btn_link{
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    color: var(--main-color) !important;
}
.app_header .btn_link:hover{
    box-shadow: none;
    background-color: #eee;
}
.app_header .btn_link i{
    font-size: 1.4rem;
}
.header_flex {
    justify-content: space-between;
    padding: .7rem 0;
}
.header_nav ul{
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
.header_nav ul li a{
    padding: 0.7rem;
    font-size: 0.85rem;
    font-weight: 400;
}
.form_header {
    margin: 0 0 1.7rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: .8rem;
}
.form_header_left{
    flex: 1;
}
.form_header h2 {
    font-weight: 600;
    color: var(--main-color);
    margin-bottom: .2rem;
    font-size: 1.8rem;
}
.form_header h2 small{
    font-size: 80%;
}
.form_header p {
    margin: 0;
    font-size: .85rem;
    font-weight: 300;
}
.form_contents {
    margin: 0rem 0 0;
}
.step_title {
    margin-bottom: 1.5rem;
}
.step_title h3 {
    font-weight: 500;
    color: var(--main-color);
    margin-bottom: .25rem;
    font-size: 1.05rem;
}
.name_grid{
    display: grid;
    grid-template-columns: 125px 1fr 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
}
.form-default .select{
    min-height: 52px;
    font-size: 0.9rem;
    color: var(--main-color);
    padding-top: 12px;
    padding-bottom: 12px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z'/%3E%3C/svg%3E");
    background-position: right .5rem center !important;
    background-repeat: no-repeat;
    background-size: 18px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
.form_body .form-outline {
    margin-bottom: 1.5rem;
}
.form-outline .form-control{
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}
.form_sec{
    border-bottom: 1px solid #eee;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
}
.form_sec:last-child{
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.form_footer,
.toolbar-bottom{
    margin: 2rem 0;
    padding-top: 1.5rem;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: flex-end;  
    gap: 0.5rem;
}
.back_btn{
    background-color: transparent;
    box-shadow: none;
}
.app_header .brand_icon{
    display: none;
}
.app_header .brand_icon img{
    max-height: 45px;
}
.mob_nav_trigger{
    display: none !important;
}
.mob_nav_overlay{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: -9;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
}
.mob_nav_open .mob_nav_overlay{
    z-index: 9;
    opacity: 1;
    visibility: visible;
}
.mob_nav_close{
    position: fixed;
    right: 12px;
    top: 12px;
}
.app_header .mob_nav_close{
    background-color: #fff !important;
}
.mob_show{
    display: none;
}
.iti{
    width: 100%;
}
.iti.iti--allow-dropdown.iti--separate-dial-code input{
    width: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
}
.iti--separate-dial-code .iti__selected-dial-code{
    font-size: 0.9rem;
}
.iti--separate-dial-code .iti__selected-flag{
    background-color: transparent;
}
.iti.iti--allow-dropdown.iti--separate-dial-code input:focus{
    border-color: #3b71ca;
    box-shadow: 0px 0 0px 1px #3b71ca,0 0px 0 0 #3b71ca,0 0px 0 0 #3b71ca !important;
}
.header_user{
    display: flex;
    align-items: center;
    gap: 0.2rem;
    padding: 0 !important;
}
.header_user:hover,
.header_user:focus{
    background-color: transparent !important;
    box-shadow: none !important;
}
.header_user::after{
    display: none;
}
.user_circle{
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    overflow: hidden;
    background-color: #673ab7;
    color: #fff;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}
.user_circle img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.user_body{
    text-transform: none;
    text-align: left;
    padding-left: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1rem;
}
.user_body span {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 160px;
    overflow: hidden;
}
.user_body span {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 160px;
    overflow: hidden;
    font-weight: 400;
    color: var(--main-color);
}
.user_body .name {
    font-size: .85rem;
}
.user_body .email {
    font-size: .8rem;
}
.dropdown-item.unlink{
    line-height: 1.6;
}
.dropdown-item.unlink:hover{
    background-color: transparent;
}
.dropdown-item{
    color: var(--main-color);
    font-size: 0.85rem;
}
.header_right .dropdown-menu{
    margin-top: 12px !important;
    padding-bottom: 0.5rem;
}
.header_right .header_user{
    margin-left: 1rem;
}
.btn.app_btn i {
    font-size: 1rem;
    margin-right: 0.5rem;
}
.form-check-label{
    font-size: 0.9rem;
    color: var(--main-color);
}
.form-check-input[type="checkbox"] {
    border-radius: 2px;
}
.home_table{
    margin: 0;
}
.table_grid{
    border: 1px solid #eee;
    border-radius: .4rem;
    overflow: hidden;
}
.grid_thead {
    background-color: #eeeef3;
    border-bottom: 1px solid #eee;
}
.grid_row {
    display: grid;
    grid-template-columns: 55px 120px auto 288px;
    grid-gap: 0;
    gap: 0;
}
.grid_col {
    padding: 1rem;
    font-size: .8rem;
    align-items: center;
    display: flex;
}
.grid_thead .grid_col {
    text-transform: uppercase;
    font-size: .75rem;
    padding: .6rem 1rem;
    color: #6b6a75;
}
.grid_col_merge{
    display: flex;
}
.grid_col_merge > .grid_col {
    flex: 1 1;
}
.grid_tbody {
    margin: 0;
}
.media_block {
    display: flex;
    align-items: center;
}
.grid_tbody .grid_col{
    color: var(--main-color);
}
.media_user{
    background-color: #673ab7;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    font-size: 0.9rem;
    text-transform: uppercase;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.media_user img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.media_body h4{
    font-weight: 500;
    font-size: .87rem;
    margin-bottom: .2rem;
    margin-top: .2rem;
}
.media_body p{
    font-size: .8rem;
    margin: 0 0 .2rem;
    color: var(--text-color);
    font-weight: 300;
}
.media_body{
    padding-left: .8rem;
    flex: 1;
}
.chip_label {
    padding: 3px 15px;
    font-size: .75rem;
    display: block;
    border-radius: 5rem;
}
.chip_label.success {
    color: #30a880;
    background-color: #bef5e3;
}
.chip_label.submitted {
    background-color: #FFD580;
}
.chip_label.resubmitted {
    background-color: #FFD580;
}
.chip_label.draft {
    background-color: #d9d9d9;
}
.chip_label.resubmit {
    background-color: #FFFF9F;
}
.chip_label.reviewed {
    background-color:lightyellow;
   
}
.chip_label.underreview {
    background-color: lightblue;
    
}
.chip_label.rejected {
    background-color:#FF7F7F;
   
}
.chip_label.approved {
    background-color: lightgreen;
   
}
.form_details {
    padding: 0;
    border-bottom: 1px solid #eee;
}
.grid_tbody .grid_row {
    border-bottom: 1px solid #eee;
}
.form_details .grid {
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
}
.grid.grid_2, .inline_grid_2 {
    grid-template-columns: 1fr 1fr;
}
.title_grid h2 {
    color: var(--secondary-color);
    font-size: .75rem;
    margin: 0 0 .8rem;
}
.inline_grid_2 {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
}
.form_details .form_label {
    margin: 0 0 4px;
    text-transform: uppercase;
    color: #6b6a75;
    font-size: .7rem;
    display: block;
}
.form_details .form_group .form_value {
    font-size: .8rem;
    margin: 0;
    font-weight: 400;
}
.grid_item{
    display: grid;
    grid-template-columns: 55px auto;
}
.grid_item_body .grid_row{
    grid-template-columns: 120px auto 288px;
}
.abstract_no{
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.abstract_comment {
    background-color: #fafbfc;
    border-bottom: 1px solid #eee;
    padding: 1rem;
}

.comment_box {
    display: inline-flex;
    flex-direction: column;
    padding: 15px;
    background-color: #f1f4f7;
    border-radius: 6px;
    margin-bottom: 10px;
}
.comment_box h6 {
    margin: 0 0 2px;
    font-size: .65rem;
    font-weight: 400;
    opacity: .7;
}
.comment_box p {
    margin-bottom: 3px;
    font-size: .75rem;
    color: var(--main-color);
    font-weight: 400;
}
.abstract_comment .comment_head h4 {
    font-size: .85rem;
    margin: 0;
}
.comment_head{
    margin-bottom: 0.8rem;
}
.comment_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
a.showMore {
    display: block;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 0px;
    color: #3b71ca;
    text-decoration: none;
}
a.showMore::after {
    content:'+ VIEW MORE';
}
a.showLess::after {
    content:'- VIEW LESS';
}
.grid_flex{
    display: flex;
    align-items: center;
    gap: 0.2rem;
}
.grid_flex .outline_round{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    box-shadow: none;
    border: 1px solid var(--main-color);
    color: var(--main-color);
}
.outline_round.delete{
    color: #d90845;
    border-color: #d90845;
}
.tooltip > div{
    font-size: 0.85rem;
}
.block_section_flex{
    background-color: #fefcfe;
    border-radius: .4rem;
    padding: 3rem 2rem;
    border: 2px dashed #eee;
}
.block_section_container{
    max-width: 580px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.block_section_container h2 {
    margin-bottom: 1rem;
}
.block_section_container p {
    margin-bottom: 1.2rem;
}
.empty_title small {
    font-size: 60%;
}
#form_draft,
#form_finish{
    display: none;
}
.toolbar-bottom button.disabled{
    display: none !important;
}
.sw-btn-next{
    padding: 0.2rem 1.4rem;
    font-size: 0.9rem;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    min-height: 46px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-color);
    color: #fff;
    border: none;
}
.sw-btn-next:hover,
.sw-btn-next:focus,
.sw-btn-next:active{
    background-color: var(--secondary-color);
    color: #fff;
}
.sw-btn-prev{
    padding: 0.2rem 1.4rem;
    font-size: 0.9rem;
    color: var(--main-color);
    font-weight: 500;
    border-radius: 4px;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    min-height: 46px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
#smartwizard .nav{
    display: flex;
    flex-direction: row;
    -moz-box-align: center;
    align-items: center;
    position: sticky;
    top: -1.5rem;
    z-index: 99;
    background-color: #fff;
    margin-bottom: 2rem;
    padding: .6rem 0;
}
#smartwizard .nav li{
    display: flex;
    align-items: center;
    padding: 0;
    flex: 1;
    justify-content: center;
    position: relative;
}
#smartwizard .nav li::after{
    content: '';
    width: 100%;
    height: 1px;
    background-color: #ddd;
    left: 0;
    top: 28px;
    position: absolute;
}

#smartwizard .nav li a{
    display: flex;
    -moz-box-align: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    position: relative;
    z-index: 9;
}
#smartwizard .nav li a .num{
    border: 2px solid #eee;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.3rem;
}
#smartwizard .nav li a .step_label{
    color: var(--main-color);
    font-size: 0.8rem;
    opacity: 0.8;
    font-weight: 500;
}
#smartwizard .nav li a.active .step_label{
    color: var(--blue-color);
    opacity: 1;
}
#smartwizard .nav li a.active .num{
    border-color: var(--blue-color);
    color: var(--blue-color);
}
#smartwizard .nav li a.done .step_label{
    color: var(--blue-color);
    opacity: 1;
}
#smartwizard .nav li a.done .num{
    background-color: var(--blue-color);
    border-color: var(--blue-color);
    color: #fff;
}
#smartwizard .nav li a.done .num{
    font-size: 0px;
}
#smartwizard .nav li a.done .num::after{
    font-size: 1.5rem;
    content: "\EB7B";
    font-family: remixicon !important;
}

.form_label{
    font-size: 0.9rem;
    margin-bottom: 0.4rem;
    font-weight: 500;
    color: var(--main-color);
}
.label_points{
    display: flex;
    gap: 0.5rem;
}
.label_points p{
    flex: 1;
    margin: 0 0 0.4rem;
    color: #757575;
    font-weight: 300;
}
.label_points i{
    color: #9ca3af;
    font-size: 1.5rem;
    line-height: 1;
}
.label_points i::before{
    display: block;
}
.form-text{
    font-size: 0.8rem;
}
.form-counter {
    font-size: 0.8rem;
    margin-top: 3px;
}
.form_inner_left .form_label{
    margin-bottom: 0.2rem;
}
.form_inner_left{
    flex: 1;
}
.form_inner_left p{
    font-size: 0.8rem;
    font-weight: 300;
    margin: 0;
}
.form_inner_head{
    margin-top: 0.5rem;
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    margin-bottom: 1rem;
}
.blue_btn_small,
.blue_btn_small:hover,
.blue_btn_small:focus,
.blue_btn_small:active{
    box-shadow: none;
    background-color: var(--blue-color) !important;
    color: #fff !important;
    padding: 0.4rem 1rem;
    text-transform: none;
}
.static_block:last-child{
    margin-bottom: 0;
}
.trumbowyg-button-pane{
    font-size: 0.9rem;
}
.trumbowyg-button-pane{
    background: #f9f9f9;
    border-bottom: 1px solid #bdbdbd;
    border-radius: 4px 4px 0 0;
}
.trumbowyg-button-pane .trumbowyg-button-group::after {
    background-color: #d4d4d4;
}
.trumbowyg-box, .trumbowyg-editor{
    border-radius: 4px;
}
.padding_bottom{
    padding-bottom: 0.5rem;
}
.tag_input .form-control{
    border: 1px solid #bdbdbd !important;
    padding: 3px 2px;
}
.tagify__input::before {
    color: rgb(34, 34, 34);
}
.tagify__tag > div::before {
    box-shadow: 0 0 0 3rem var(--tag-bg) inset;
}
.tagify__tag__removeBtn:hover + div::before {
    box-shadow: 0 0 0 3rem var(--tag-remove-bg,rgba(211,148,148,.3)) inset !important;
}
#smartwizard .tab-content{
    height: auto !important;
}
.box_border{
    border: 1px solid #eee;
    padding: 1rem 1.2rem;
    border-radius: .4rem;
    overflow: hidden;
    background-color: #f7f7fb;
    margin-bottom: 1rem;
}
.form-switch .form-check-input{
    border-radius: 50px;
}
.switch_inline{
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    gap: 1rem;
}
.form-switch .form-check-input {
    width: 2.5rem;
    height: 1.5rem;
    margin-top: 0em;
}
.form-switch .form-check-input::after {
    margin-top: 0.1rem;
    margin-left: 0.1rem;
    transition: all 0.2s ease-in-out;
}
.form-switch .form-check-input::before {
    display: none !important;
}
.form-switch .form-check-input:checked[type="checkbox"]::after {
    background-color: #fff;
    margin-top: 0.1rem;
}

.form-switch .form-check-input[type="checkbox"]:checked {
    background-image: none;
    background-color: rgb(101, 196, 102);
}
.select2-container{
    width: 100% !important;
}
.select2-container--default .select2-selection--single{
    font-weight: 400 !important;
    line-height: 1.6 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #bdbdbd !important;
    height: auto !important;
    min-height: 52px;
}
.select2-container--focus .select2-selection--single{
    border-color: #3b71ca !important;
    box-shadow: inset 0 0 0 1px #3b71ca;
}
.select2-container--default .select2-selection--single .select2-selection__rendered{
    height: 52px;
    padding-left: 0.8rem;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: var(--main-color);
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: var(--main-color) !important;
    font-size: 0.9rem !important;
    opacity: 0.5 !important;
    height: 100%;
    display: flex;
    align-items: center;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 50px !important;
    width: 35px !important;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 2px !important;
    right: 2px !important;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
    height: 48px !important;
    margin-right: 36px !important;
    width: 25px;
    background-color: #fff;
    margin-top: 2px;
}
.select2-selection__arrow b{
    display: none;
}
.select2-selection__arrow::after{
    content: "\EA4E";
    font-family: remixicon !important;
    font-size: 1.1rem;
    color: var(--main-color);
}
.select2-selection__clear span{
    display: none;
}
.select2-selection__clear::after{
    content: "\EB99";
    font-family: remixicon !important;
    font-size: 1.1rem;
    color: var(--main-color);
}
.custom_group{
    margin-bottom: 1.5rem;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable{
    background-color: var(--blue-color);
}
.select2-results__option{
    font-size: 0.9rem;
    font-weight: 400;
}
.select2-container--default .select2-results__option--selected {
    background-color: #f7f7fb;
}
.upload_block{
    margin-bottom: 2rem;
}
.dropzone {
    background-color: #fefcfe;
    border-radius: .4rem;
    padding: 3rem 2rem;
    border: 2px dashed #dbdbdb;
    margin-bottom: 1rem;
}
.dz-default.dz-message{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
}
.dz-default.dz-message::before{
    content: "\F24C";
    font-family: remixicon !important;
    font-size: 2rem;
    color: var(--blue-color);
    margin-bottom: 0.5rem;
    line-height: 1;
}
.notes h5{
    font-size: 0.85rem;
    font-weight: 500;
    color: var(--secondary-color);
}

.notes ul {
    padding: 0 0 0 15px;
}
.notes ul li{
    font-size: 0.85rem;
}
.checkbox_group{
    margin-bottom: 0.8rem;
}
.review_head{
    border-bottom: 1px solid #eee;
    padding-bottom: 0.6rem;
    margin-bottom: 1rem;
}
.ab_title {
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
}
.ab_author {
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--text-color);
}
.ab_institute {
    font-size: 0.85rem;
    font-weight: 300;
    color: var(--text-color);
    margin-top: 0.8rem;
}
.review_body p{
    line-height: 1.7;
}
.review_body p strong{
    color: var(--main-color);
}
.keywords {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}
.keywords span {
    background-color: #E5E5E5;
    color: var(--main-color);
    padding: 0.4rem 1rem;
    border-radius: 4px;
    display: inline-flex;
    font-size: 0.85rem;
    margin-bottom: 0.4rem;
    font-weight: 400;
}
.review_category {
    display: flex;
    gap: 2.5rem;
}
.review_label {
    font-size: 0.85rem;
    color: #2f80ed;
    margin-bottom: 0.2rem;
}
.review_category_item {
    margin-bottom: 0.7rem;
}
.review_category_item h5 {
    font-size: 0.9rem;
}
.success_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 5rem;
    min-height: 70vh;
}
.success_icon {
    position: relative;
    top: 2.5rem;
    margin-top: -6rem;
}
.success_icon img {
    width: 16rem;
    height: 16rem;
}
.success_wrapper h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}
.success_wrapper h5 {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: .8rem;
}
.success_wrapper h3 {
    margin-bottom: 2rem;
    font-size: 1.1rem;
}


@media screen and (max-width: 1092px){
    .abstract_grid{
        grid-template-columns: 340px auto;
    }
}
@media screen and (max-width: 991px){
    .abstract_grid{
        grid-template-columns: 100%;
    }
    .abstract_banner{
        display: none;
    }
    .app_header .brand_icon{
        display: block;
    }
    .app_header{
        padding: 0 1.2rem;
    }
}
@media screen and (max-width: 767px){
    .name_grid{
        grid-template-columns: 1fr 1fr;
    }
    .mob_nav_trigger{
        display: flex !important;
    }
    .header_back_btn{
        display: none !important;
    }
    .header_nav{
        position: fixed;
        background-color: #fff;
        top: 0;
        left: -260px;
        height: 100%;
        align-items: flex-start;
        padding: 1.2rem;
        width: 260px;
        z-index: 99;
        transition: all 0.2s ease-in-out;
    }
    .mob_nav_open .header_nav{
        left: 0;
        padding-bottom: 10rem;
        overflow: auto;
    }
    .header_nav ul{
        flex-direction: column;
        padding-top: 2rem;
    }
    .header_nav ul li a {
        padding: 0.7rem;
        display: flex;
    }
    .app_footer{
        display: none;
        left: -260px;
        transition: all 0.2s ease-in-out;
    }
    .mob_nav_open .app_footer{
        display: flex;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 999;
        width: 260px;
    }
    .mob_nav_open .app_footer .footer_flex{
        flex-direction: column-reverse;
    }
    .mob_nav_open .footer_flex ul {
        gap: 6px;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 1rem;
    }
    .footer_flex p{
        font-size: 0.8rem;
    }
    .mob_nav_open .footer_flex ul a{
        font-size: 0.8rem;
    }
    .form_contents {
        margin: 0rem 0 0;
    }
    .form_footer,
    .toolbar-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        margin: 0;
        height: 75px;
        padding: 0 1.2rem;
        align-items: center;
        box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.1);
        z-index: 99;
    }
    .wrap_fluid{
        padding-bottom: 75px;
    }
    .mob_show{
        display: block;
    }
    .header_nav ul li a{
        padding-left: 0;
        padding-right: 0;
    }
    .header_user .user_body{
        display: none;
    }
    .add_submission {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        margin: 0;
        height: 75px;
        padding: 0 1.2rem;
        align-items: center;
        box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.1);
        z-index: 9;
        display: flex;
        justify-content: flex-end;
    }
    .add_submission .btn{
        width: 100%;
    }
    .grid_item_body .grid_row {
        grid-template-columns: 100%;
        gap: 0;
    }
    .title_col, .abstract_no{
        display: none;
    }
    .grid_item{
        grid-template-columns: 100%;
    }
    .grid_thead{
        display: none;
    }
    .grid_item_body .grid_col:nth-child(2){
        padding-top: 0;
    }
    .grid_col_merge{
        border-top: 1px solid #eee;
        background-color: #f4f4f9;
    }
    .grid_col_merge .grid_col:nth-child(2){
        padding-top: 1rem;
    }
    .grid_col_merge > .grid_col {
        flex: inherit;
    }
    .grid_col_merge .grid_col:first-child{
        flex: 1;
    }
    .label_points {
        margin-bottom: 1rem;
        margin-top: -1rem;
    }
    .sw-toolbar-elm.toolbar.toolbar-bottom .btn {
        flex: 1;
    }
    .name_grid .form-default:first-child,
    .name_grid div:nth-child(2){
        margin-bottom: 0.5rem;
    }
}
@media screen and (max-width: 640px){
    .footer_flex{
        flex-direction: column;
        padding: 1rem 1.2rem;
    }
    .footer_flex p{
        margin-bottom: 5px;
    }
    .auth_heading h1 {
        font-size: 2.4rem;
    }
    .brand_icon img {
        max-height: 45px !important;
    }
    .auth_heading h3 {
        font-size: 1rem;
    }
    .auth_heading {
        margin-bottom: 1rem;
    }
    .block_section_flex {
        padding: 2rem 1rem;
    }
}
@media screen and (max-width: 580px){
    .form_details .grid.grid_2{
        grid-template-columns: 100%;
    }
    #smartwizard .nav li a .step_label{
        display: none;
    }
    #smartwizard .nav li a .num{
        width: 1.8rem;
        height: 1.8rem;
        font-size: 0.8rem;
    }
    #smartwizard .nav li a {
        padding: 0px 0.5rem;
    }
    #smartwizard .nav li::after {
        top: 15px;
    }
    #smartwizard .nav{
        margin-bottom: 1rem;
    }
    .form_inner_head {
        gap: 0.5rem;
        flex-direction: column;
        align-items: flex-start;
    }
    .switch_inline{
        flex-direction: column !important;
    }
    .name_grid .form-default:first-child,
    .name_grid div:nth-child(2){
        margin-bottom: 1.5rem;
    }
    .review_category{
        flex-direction: column;
        gap: 0;
    }
}
@media screen and (max-width: 480px){
    .name_grid {
        grid-template-columns: 100%;
        gap: 0;
    }
}

// bibin - added

.forms_error_info {
    margin-top: 5px;
    font-size: 13px;
    color: var(--secondary-color);
    font-weight: 500;
}

.ck-editor__editable_inline { height:250px !important; }

.mr-1 {
    margin-right: 1%;

}
.ml-3 {
    margin-left: 3%;

}
.text-right {
    text-align: right;
}
.video-link {
    color: #3b71ca;
    cursor : pointer;
  }
  .video-abstract-note {
    color: red;
  }
 .required-field {
    color: red;
 }
 .submissions_container{
    margin: 0 auto;
    width: 100%;
    // max-width: 900px;
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 1.2rem;
}


